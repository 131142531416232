
import { Component, mixins } from 'nuxt-property-decorator';
import WidgetMixin from '~/mixins/widget.mixin';
import DeviceMixin from '~/mixins/device.mixin';
import CardCarouselMixin from '~/mixins/card-carousel.mixin';

@Component({
  name: 'dn-banner-carousel',
})
export default class BannerCarousel extends mixins(
  WidgetMixin,
  DeviceMixin,
  CardCarouselMixin,
) {}
