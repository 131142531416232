
import { mixins, Component, Prop } from 'nuxt-property-decorator';
import CardCarouselMixin from '~/mixins/card-carousel.mixin';

@Component({
  name: 'dn-banner-carousel-four-columns',
})
export default class BannerCarouselFourColumns extends mixins(
  CardCarouselMixin
) {
  private cols: number = 4;
}
